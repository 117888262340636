/**
 * The app index
 */
import React from "react";
import { render, hydrate } from "react-dom";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import "./index.sass";
import Loadable from "react-loadable";
import "font-awesome/css/font-awesome.min.css";
import Root from "./routes/Root";
import I18n from "redux-i18n";
import { rootSaga, configureStore } from "@bkry/bowline-redux";
import { createSsr } from "@issr/core";
import { Web3ReactProvider } from "@web3-react/core";
import { metaMaskHooks, metaMask } from "@bkry/bowline-connectors";

/** create a store */
const { store, history } = configureStore();

/** run sagas */
store.runSaga(rootSaga);

/** build the service worker configuration object */
// var config = configureWorker(store)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// if (!isServer) {
// 	serviceWorkerRegistration.register(config);
// } else {
// 	serviceWorkerRegistration.unregister();
//}

const connectors = [[metaMask, metaMaskHooks]];

const SSR = createSsr();

/** The application */
const Application = () => {
  const { translations, lang } = useSelector((state) => state.i18nState);

  return (
    <Web3ReactProvider connectors={connectors}>
      <I18n
        translations={translations}
        initialLang={lang}
        initialized={!!translations[lang]}
      >
        <ConnectedRouter history={history}>
          <Root />
        </ConnectedRouter>
      </I18n>
    </Web3ReactProvider>
  );
};

/** the root element to load react */
const root = document.querySelector("#root");

/**
 * the index main
 */
if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(
      <SSR>
        <Provider store={store}>
          <Application />
        </Provider>
      </SSR>,
      root
    );
  });
} else {
  // If we're not running on the server, just render like normal
  render(
    <SSR>
      <Provider store={store}>
        <Application />
      </Provider>
    </SSR>,
    root
  );
}
