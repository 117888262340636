import { NetworkLabel } from "@bkry/bowline-components/NetworkLabel";
import {
  getContracts,
  getPartnerContracts,
} from "@bkry/bowline-redux/contracts";
import { getProjectRelationships } from "@bkry/bowline-redux/projects";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Badge, FormGroup, FormText, Input, Label } from "reactstrap";
import TrunkatedAddress from "./TrunkatedAddress";

/**
 * Table container used as app default landing page
 */
const ContractSelect = (props, context) => {
  const {
    projectId,
    selectedContract,
    setSelectedContract,
    field,
    disableNonManageable,
    description,
    hideNonManageable,
    showUnlockPartnerContracts,
    setShowUnlockPartnerContracts,
    isActive,
    isMulti,
  } = props;
  const projectRelationships = useSelector((state) =>
    getProjectRelationships(state, projectId)
  );

  const [previousSelectedContract, setPreviousSelectedContract] =
    useState(null);

  const contracts = useSelector((state) =>
    getContracts(
      state,
      projectRelationships?.contracts?.data?.map((contract) => contract.id)
    )
  );

  const partnerContracts = useSelector((state) => getPartnerContracts(state));

  const optionsSource = showUnlockPartnerContracts
    ? partnerContracts
    : contracts;

  const contractsOptions = optionsSource
    ?.filter((contract) => !hideNonManageable || contract?.manageable_metadata)
    .map((contract) => ({
      label: contract?.name
        ? `${contract.name} ${contract.address} ${contract.network}`
        : contract?.address,
      value: { address: contract?.address, network: contract?.network },
      isDisabled: disableNonManageable && !contract?.manageable_metadata,
    }));

  const formatContractOptionLabel = (option) => {
    const contract =
      optionsSource?.find(
        (c) =>
          c.address === option.value?.address &&
          c.network === option.value?.network
      ) || null;

    return (
      <span className="d-flex align-items-center">
        {contract?.logo && (
          <img
            src={contract?.logo}
            alt={contract?.name}
            width={40}
            height={40}
            className="me-1"
          />
        )}
        <span className="text-muted me-1">{contract?.name}</span>
        <TrunkatedAddress address={option?.value?.address} />
        <span className="ms-1 text-muted">
          (<NetworkLabel network={contract?.network} />)
        </span>
        {contract?.unlock_partner_contract && (
          <Badge className="ms-1">Partner</Badge>
        )}
      </span>
    );
  };

  useEffect(() => {
    if (isActive && previousSelectedContract !== selectedContract) {
      setSelectedContract && setSelectedContract(field?.value?.value);
      setPreviousSelectedContract(selectedContract);
    }
  }, [selectedContract, isActive]);

  const defaultValue = !isMulti
    ? selectedContract
      ? contractsOptions?.find(
          (option) =>
            option.value === selectedContract ||
            (option.value?.address === selectedContract?.address &&
              option.value?.network === selectedContract?.network)
        )
      : undefined
    : selectedContract
    ? contractsOptions.filter((contract) =>
        selectedContract?.find(
          (c) =>
            c.address === contract.value?.address &&
            c.network === contract.value?.network
        )
      )
    : undefined;

  return (
    <>
      <FormGroup>
        <Label className="bold">{context.t("Smart Contract")}</Label>
        {description && (
          <div>
            <FormText>{description}</FormText>
          </div>
        )}
        {setShowUnlockPartnerContracts && (
          <FormGroup switch className="mb-2">
            <Input
              type="switch"
              role="switch"
              onClick={() => {
                setShowUnlockPartnerContracts(!showUnlockPartnerContracts);
              }}
              checked={showUnlockPartnerContracts}
            />
            <Label check>Show unlock partner contracts</Label>
          </FormGroup>
        )}
        <Select
          {...field}
          isClearable
          isMulti={isMulti}
          options={contractsOptions}
          formatOptionLabel={formatContractOptionLabel}
          defaultValue={defaultValue}
          onChange={(v) => {
            if (field?.onChange) field?.onChange(v);
            if (!field && setSelectedContract) setSelectedContract(v);
          }}
          styles={{
            menu: (base) => ({ ...base, zIndex: 20 }),
          }}
        />
      </FormGroup>
    </>
  );
};

ContractSelect.contextTypes = {
  t: PropTypes.func,
};

/**  define proptypes for EnsName  */
ContractSelect.propTypes = {
  setCurrentMintTierType: PropTypes.func,
  projectId: PropTypes.string,
  mintTierData: PropTypes.object,
  mintTierTypeOptions: PropTypes.object,
  selectedContract: PropTypes.object,
  setSelectedContract: PropTypes.func,
  field: PropTypes.object,
  disableNonManageable: PropTypes.bool,
  description: PropTypes.string,
  hideNonManageable: PropTypes.bool,
  showUnlockPartnerContracts: PropTypes.bool,
  setShowUnlockPartnerContracts: PropTypes.func,
  isActive: PropTypes.bool,
  isMulti: PropTypes.bool,
};

/**  define defaultProps for EnsName  */
ContractSelect.defaulProps = {
  isMulti: false,
};

/** export the component as redux connected component */
export default ContractSelect;
