/**
 * The Home container
 */
import {
  currentCreatorId,
  getCreatorRelationships,
  showCreator,
} from "@bkry/bowline-redux/creators";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  MdAddCircleOutline,
  MdCardGiftcard,
  MdFormatAlignJustify,
  MdOutlineAccountBalanceWallet,
  MdOutlineBrush,
  MdOutlineCasino,
  MdOutlineCategory,
  MdOutlineChat,
  MdOutlineChecklist,
  MdOutlineChromeReaderMode,
  MdOutlineCopyright,
  MdOutlineDesktopWindows,
  MdOutlineEditRoad,
  MdOutlineEvent,
  MdOutlineFilter,
  MdOutlineFirstPage,
  MdOutlineGroups,
  MdOutlineHandshake,
  MdOutlineHome,
  MdOutlineHowToVote,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineLastPage,
  MdOutlineLayers,
  MdOutlineLibraryBooks,
  MdOutlineLink,
  MdOutlineList,
  MdOutlineLock,
  MdOutlineLogout,
  MdOutlineMenu,
  MdOutlineMonetizationOn,
  MdOutlineMultipleStop,
  MdOutlineNotificationsActive,
  MdOutlineQuestionMark,
  MdOutlineReceiptLong,
  MdOutlineRemoveFromQueue,
  MdOutlineSettings,
  MdOutlineShoppingCart,
  MdOutlineSupervisedUserCircle,
  MdOutlineVpnKey,
  MdOutlineWeb,
  MdOutlinedFlag,
  MdReceiptLong,
  MdTempleBuddhist,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink as RouterNavLink,
  matchPath,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  UncontrolledTooltip,
} from "reactstrap";
import ContractAddModal from "../ContractAddModal";
import TokenCreateModeModal from "../Token/TokenCreateModeModal";
import LicenseTooltip from "../Utils/LicenseTooltip";

/**
 * Home container used as app default landing page
 *
 */

const Sidebar = (props, context) => {
  const { onCollapse, mobileSidebarActive } = props;
  const { projectId } = useParams();
  const currentYear = new Date().getFullYear();
  const creatorId = useSelector((state) => currentCreatorId(state));
  const currentCreatorRelationships = useSelector((state) =>
    getCreatorRelationships(state, creatorId)
  );
  const [inactive, setInactive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState([]);
  const projectData = useSelector((state) =>
    getProjectAttributes(state, projectId)
  );

  const dispatch = useDispatch();

  const { account, connector } = useWeb3React();

  const menuItems =
    projectId && projectId !== "new"
      ? [
          {
            id: "0",
            name: <span>{context.t("Create New")}</span>,
            cta: true,
            icon: (
              <div
                id="create_new"
                className="d-flex align-items-center justify-content-center"
              >
                <MdAddCircleOutline size={20} />
                {inactive && (
                  <UncontrolledTooltip placement="right" target="create_new">
                    {context.t("Create New")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
            subMenus: [
              {
                id: "0-1",
                name: <span>{context.t("Unlockable")}</span>,
                exact: true,
                path: `/projects/${projectId}/contents/new`,
                icon: (
                  <div
                    id="create_new_unlockable_content"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLock size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="create_new_unlockable_content"
                      >
                        {context.t("Create New Unlockable")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "0-2",
                name: <span>{context.t("Voting")}</span>,
                exact: true,
                path: `/projects/${projectId}/votings/new`,
                icon: (
                  <div
                    id="create_new_voting"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineHowToVote size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="create_new_voting"
                      >
                        {context.t("Create New Voting")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "0-3",
                name: <span>{context.t("Event")}</span>,
                exact: true,
                path: `/projects/${projectId}/events/new`,
                icon: (
                  <div
                    id="create_new_event"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineEvent size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="create_new_event"
                      >
                        {context.t("Create New Event")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "0-4",
                name: <span>{context.t("Raffle")}</span>,
                exact: true,
                path: `/projects/${projectId}/raffles/new`,
                icon: (
                  <div
                    id="create_new_raffle"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineCasino size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="create_new_raffle"
                      >
                        {context.t("Create New Raffle")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "0-5",
                name: <span>{context.t("Partner Deal")}</span>,
                exact: true,
                path: `/projects/${projectId}/deals/new`,
                icon: (
                  <div
                    id="create_new_partner_deal"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineHandshake size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="create_new_partner_deal"
                      >
                        {context.t("Create New Partner Deal")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "0-6",
                name: <span>{context.t("Announcement")}</span>,
                exact: true,
                path: `/projects/${projectId}/announcements/new`,
                icon: (
                  <div
                    id="create_new_announcement"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineNotificationsActive size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="create_new_announcement"
                      >
                        {context.t("Create New Announcement")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "0-7",
                special: (
                  <TokenCreateModeModal
                    trigger={
                      <div className="sidebar__menu-heading nav-link cursor-pointer d-flex align-items-center">
                        <div className="sidebar__menu-heading-icon">
                          <div
                            id="create_new_token"
                            className="d-flex align-items-center justify-content-center"
                          >
                            <MdOutlineFilter size={20} />
                            {inactive && (
                              <UncontrolledTooltip
                                placement="right"
                                target="create_new_token"
                              >
                                {context.t("Create New Token")}
                              </UncontrolledTooltip>
                            )}
                          </div>
                        </div>
                        <span className="sidebar__menu-heading-name ms-2">
                          <span>{context.t("Token")}</span>
                        </span>
                      </div>
                    }
                  />
                ),
              },
              {
                id: "0-8",
                special: (
                  <ContractAddModal
                    trigger={
                      <div className="sidebar__menu-heading nav-link cursor-pointer d-flex align-items-center">
                        <div className="sidebar__menu-heading-icon">
                          <div
                            id="create_new_contract"
                            className="d-flex align-items-center justify-content-center"
                          >
                            <MdOutlineReceiptLong size={20} />
                            {inactive && (
                              <UncontrolledTooltip
                                placement="right"
                                target="create_new_contract"
                              >
                                {context.t("Create New Contract")}
                              </UncontrolledTooltip>
                            )}
                          </div>
                        </div>
                        <span className="sidebar__menu-heading-name ms-2">
                          <span>{context.t("Contract")}</span>
                        </span>
                      </div>
                    }
                  />
                ),
              },
            ],
          },
          {
            id: "1",
            name: <span>{context.t("Dasboard")}</span>,
            exact: true,
            path: `/projects/${projectId}`,
            icon: (
              <div
                id="dashboard"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineHome size={20} />
                {inactive && (
                  <UncontrolledTooltip placement="right" target="dashboard">
                    {context.t("Dashboard")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
          },

          {
            id: "2",
            name: <span>{context.t("Gated Contents")}</span>,
            icon: (
              <div
                id="token_gated_content"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineVpnKey size={20} />
                {inactive && (
                  <UncontrolledTooltip
                    placement="right"
                    target="token_gated_content"
                  >
                    {context.t("Gated Contents")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
            subMenus: [
              {
                id: "2-1",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.unlockables_management
                        ? null
                        : "unlockable_contents"
                    }`}
                  >
                    {context.t("Unlockables")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/contents`,
                icon: projectData?.license_rules?.unlockables_management ? (
                  <div
                    id="unlockable_contents"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLock size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"unlockable_contents"}
                      >
                        {context.t("Unlockables")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="unlockable_contents"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLock size={20} />
                    <LicenseTooltip target={"unlockable_contents"} />
                  </div>
                ),
              },
              {
                id: "2-2",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.unlockables_management
                        ? null
                        : "votings"
                    }`}
                  >
                    {context.t("Votings")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/votings`,
                icon: projectData?.license_rules?.unlockables_management ? (
                  <div id="votings">
                    <MdOutlineHowToVote size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target={"votings"}>
                        {context.t("Votings")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div id="votings">
                    <MdOutlineHowToVote size={20} />
                    <LicenseTooltip target={"votings"} />
                  </div>
                ),
              },
              {
                id: "2-3",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.unlockables_management
                        ? null
                        : "events"
                    }`}
                  >
                    {context.t("Events")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/events`,
                icon: projectData?.license_rules?.unlockables_management ? (
                  <div
                    id="events"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineEvent size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target={"events"}>
                        {context.t("Events")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="events"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineEvent size={20} />
                    <LicenseTooltip target={"events"} />
                  </div>
                ),
              },
              {
                id: "2-4",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.unlockables_management
                        ? null
                        : "raffles"
                    }`}
                  >
                    {context.t("Raffles")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/raffles`,
                icon: projectData?.license_rules?.unlockables_management ? (
                  <div
                    id="raffles"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineCasino size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target={"raffles"}>
                        {context.t("Raffles")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="raffles"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineCasino size={20} />
                    <LicenseTooltip target={"raffles"} />
                  </div>
                ),
              },
              {
                id: "2-5",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.unlockables_management
                        ? null
                        : "events"
                    }`}
                  >
                    {context.t("Partner Deals")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/deals`,
                icon: projectData?.license_rules?.unlockables_management ? (
                  <div
                    id="partner_deals"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineHandshake size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"partner_deals"}
                      >
                        {context.t("Partner Deals")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="partner_deals"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineHandshake size={20} />
                    <LicenseTooltip target={"partner_deals"} />
                  </div>
                ),
              },
              {
                id: "2-6",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.unlockables_management
                        ? null
                        : "discussions"
                    }`}
                  >
                    {context.t("Discussions")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/discussions`,
                icon: projectData?.license_rules?.unlockables_management ? (
                  <div
                    id="discussions"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineChat size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"discussions"}
                      >
                        {context.t("Discussions")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="discussions"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineChat size={20} />
                    <LicenseTooltip target={"discussions"} />
                  </div>
                ),
              },
            ],
          },
          {
            id: "3",
            name: <span>{context.t("dApp")}</span>,
            icon: (
              <div
                id="dapp_management"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineRemoveFromQueue size={20} />
                {inactive && (
                  <UncontrolledTooltip
                    placement="right"
                    target="dapp_management"
                  >
                    {context.t("dApp")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
            subMenus: [
              {
                id: "3-1",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.dapp_management
                        ? null
                        : "appearance"
                    }`}
                  >
                    {context.t("Appearance")}
                  </span>
                ),
                exact: true,
                path: `/projects/${projectId}/settings/design/edit`,
                icon: projectData?.license_rules?.dapp_management ? (
                  <div
                    id="appearance"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineBrush size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="appearance"
                      >
                        {context.t("Appearance")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="appearance"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineBrush size={20} />
                    <LicenseTooltip target={"appearance"} />
                  </div>
                ),
              },
              {
                id: "3-2",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.dapp_management
                        ? null
                        : "navigation_items"
                    }`}
                  >
                    {context.t("Navigation Items")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/navigation-items`,
                icon: projectData?.license_rules?.dapp_management ? (
                  <div id="navigation_items">
                    <MdOutlineMenu size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"navigation_items"}
                      >
                        {context.t("Navigation Items")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div id="navigation_items">
                    <MdOutlineMenu size={20} />
                    <LicenseTooltip target={"navigation_items"} />
                  </div>
                ),
              },
              {
                id: "3-3",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.dapp_management
                        ? null
                        : "pages"
                    }`}
                  >
                    {context.t("Pages")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/pages`,
                icon: projectData?.license_rules?.dapp_management ? (
                  <div
                    id="pages"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineWeb size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target={"pages"}>
                        {context.t("Pages")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="pages"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineWeb size={20} />
                    <LicenseTooltip target={"pages"} />
                  </div>
                ),
              },
              {
                id: "3-4",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.dapp_management
                        ? null
                        : "faqs"
                    }`}
                  >
                    {context.t("Faqs")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/faqs`,
                icon: projectData?.license_rules?.dapp_management ? (
                  <div id="faqs">
                    <MdOutlineQuestionMark size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target={"faqs"}>
                        {context.t("Faqs")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div id="faqs">
                    <MdOutlineQuestionMark size={20} />
                    <LicenseTooltip target={"faqs"} />
                  </div>
                ),
              },
              {
                id: "3-5",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.dapp_management
                        ? null
                        : "faq_lists"
                    }`}
                  >
                    {context.t("Faq Lists")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/faqlists`,
                icon: projectData?.license_rules?.dapp_management ? (
                  <div id="faq_lists">
                    <MdOutlineList size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"faq_lists"}
                      >
                        {context.t("Faq Lists")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div id="faq_lists">
                    <MdOutlineList size={20} />
                    <LicenseTooltip target={"faq_lists"} />
                  </div>
                ),
              },
              {
                id: "3-6",
                name: "Roadmaps",
                exact: false,
                path: `/projects/${projectId}/roadmaps`,
                isActiveRule: (match, location) => {
                  return match
                    ? true
                    : location.pathname.includes("/roadmaps/");
                },
                icon: (
                  <div
                    id="roadmaps"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineEditRoad size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target="roadmaps">
                        {context.t("Roadmaps")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "3-7",
                name: <span>{context.t("Shop")}</span>,
                exact: false,
                path: `/projects/${projectId}/shop`,
                icon: (
                  <div
                    id="shop"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineShoppingCart size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target="shop">
                        {context.t("Shop")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "3-8",
                name: <span>{context.t("Private Exchange")}</span>,
                exact: false,
                path: `/projects/${projectId}/exchange`,
                icon: (
                  <div
                    id="private_exchange"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineMultipleStop size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="private_exchange"
                      >
                        {context.t("Private Exchange")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
            ],
          },
          {
            id: "4",
            name: "Community",
            icon: (
              <div
                id="announcements"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineGroups size={20} />
                {inactive && (
                  <UncontrolledTooltip placement="right" target="announcements">
                    {context.t("Community")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
            subMenus: [
              {
                id: "4-1",
                name: <span>{context.t("Holders")}</span>,
                exact: false,
                path: `/projects/${projectId}/holders`,
                icon: (
                  <div
                    id="holders"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineGroups size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target="holders">
                        {context.t("Holders")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "4-2",
                name: "Announcements",
                exact: false,
                path: `/projects/${projectId}/announcements`,
                isActiveRule: (match, location) => {
                  return match
                    ? true
                    : location.pathname.includes("/announcements/");
                },
                icon: (
                  <div
                    id="announcements"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineNotificationsActive size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="announcements"
                      >
                        {context.t("Announcements")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "4-3",
                name: "Tribes",
                exact: false,
                path: `/projects/${projectId}/tribes`,
                isActiveRule: (match, location) => {
                  return match ? true : location.pathname.includes("/tribes/");
                },
                icon: (
                  <div
                    id="tribes"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdTempleBuddhist size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target="tribes">
                        {context.t("Tribes")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
            ],
          },
          {
            id: "5",
            name: (
              <span
                id={`${
                  projectData?.license_rules?.token_management ? null : "tokens"
                }`}
              >
                {context.t("Tokens")}
              </span>
            ),
            exact: false,
            path: `/projects/${projectId}/tokens`,
            isActiveRule: (match, location) => {
              return match ? true : location.pathname.includes("/tokens/");
            },
            icon: projectData?.license_rules?.token_management ? (
              <div
                id="tokens"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineFilter size={20} />
                {inactive && (
                  <UncontrolledTooltip placement="right" target={"tokens"}>
                    {context.t("Tokens")}
                  </UncontrolledTooltip>
                )}
              </div>
            ) : (
              <div
                id="tokens"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineFilter size={20} />
                <LicenseTooltip target={"tokens"} />
              </div>
            ),
          },
          {
            id: "6",
            name: <span>{context.t("Contracts")}</span>,
            icon: (
              <div
                id="contracts_management"
                className="d-flex align-items-center justify-content-center"
              >
                <MdFormatAlignJustify size={20} />
                {inactive && (
                  <UncontrolledTooltip
                    placement="right"
                    target="contracts_management"
                  >
                    {context.t("Contracts")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
            subMenus: [
              {
                id: "6-1",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.contract_management
                        ? null
                        : "contracts"
                    }`}
                  >
                    {context.t("Contracts")}
                  </span>
                ),
                exact: true,
                isActiveRule: (match, location) => {
                  const included = location.pathname.includes(
                    `/projects/${projectId}/contracts`
                  );
                  const excludes =
                    !location.pathname.includes(`/tokens`) &&
                    !location.pathname.includes(`/tiers`);
                  return excludes ? included : false;
                },
                path: `/projects/${projectId}/contracts`,
                icon: projectData?.license_rules?.contract_management ? (
                  <div
                    id="contracts"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineReceiptLong size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"contracts"}
                      >
                        {context.t("Contracts")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="contracts"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineReceiptLong size={20} />
                    <LicenseTooltip target={"contracts"} />
                  </div>
                ),
              },

              {
                id: "6-2",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.contract_management
                        ? null
                        : "utility_contracts"
                    }`}
                  >
                    {context.t("Utility Contracts")}
                  </span>
                ),
                exact: true,
                isActiveRule: (match, location) => {
                  const included = location.pathname.includes(
                    `/projects/${projectId}/utility-contracts`
                  );
                  const excludes =
                    !location.pathname.includes(`/tokens`) &&
                    !location.pathname.includes(`/tiers`);
                  return excludes ? included : false;
                },
                path: `/projects/${projectId}/utility-contracts`,
                icon: projectData?.license_rules?.contract_management ? (
                  <div
                    id="utility_contracts"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdReceiptLong size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"utility_contracts"}
                      >
                        {context.t("Utility Contracts")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="utility_contracts"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdReceiptLong size={20} />
                    <LicenseTooltip target={"utility_contracts"} />
                  </div>
                ),
              },
              {
                id: "6-3",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.allowlist_management
                        ? null
                        : "allowlist"
                    }`}
                  >
                    {context.t("Allowlist")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/allowlist`,
                icon: projectData?.license_rules?.allowlist_management ? (
                  <div
                    id="allowlist"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineChecklist size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"allowlist"}
                      >
                        {context.t("Allowlist")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="allowlist"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineChecklist size={20} />
                    <LicenseTooltip target={"allowlist"} />
                  </div>
                ),
              },
              {
                id: "6-4",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.allowlist_management
                        ? null
                        : "mint_tiers"
                    }`}
                  >
                    {context.t("Mint Tiers")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/tiers`,
                isActiveRule: (match, location) => {
                  const included = location.pathname.includes("/tiers");
                  const excludes = !location.pathname.includes(`/vouchers`);
                  return excludes ? included : false;
                },
                icon: projectData?.license_rules?.contract_management ? (
                  <div
                    id="mint_tiers"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLayers size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"mint_tiers"}
                      >
                        {context.t("Mint Tiers")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="mint_tiers"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLayers size={20} />
                    <LicenseTooltip target={"mint_tiers"} />
                  </div>
                ),
              },
              {
                id: "6-5",
                name: (
                  <span
                    id={`${
                      projectData?.license_rules?.allowlist_management
                        ? null
                        : "mint_vouchers"
                    }`}
                  >
                    {context.t("Mint Vouchers")}
                  </span>
                ),
                exact: false,
                path: `/projects/${projectId}/vouchers`,
                isActiveRule: (match, location) => {
                  return match ? true : location.pathname.includes("/vouchers");
                },
                icon: projectData?.license_rules?.contract_management ? (
                  <div
                    id="mint_vouchers"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdCardGiftcard size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target={"mint_vouchers"}
                      >
                        {context.t("Mint Vouchers")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ) : (
                  <div
                    id="mint_vouchers"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLayers size={20} />
                    <LicenseTooltip target={"mint_vouchers"} />
                  </div>
                ),
              },
            ],
          },

          {
            id: "7",
            name: <span>{context.t("Analytics")}</span>,
            exact: false,
            path: `/projects/${projectId}/analytics`,
            icon: (
              <div
                id="analytics"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlinedFlag size={20} />
                {inactive && (
                  <UncontrolledTooltip placement="right" target="analytics">
                    {context.t("Analytics")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
          },
          {
            id: "8",
            name: <span>{context.t("Settings")}</span>,
            spacing: true,
            icon: (
              <div
                id="settings"
                className="d-flex align-items-center justify-content-center"
              >
                <MdOutlineSettings size={20} />
                {inactive && (
                  <UncontrolledTooltip placement="right" target="settings">
                    {context.t("Settings")}
                  </UncontrolledTooltip>
                )}
              </div>
            ),
            subMenus: [
              {
                id: "8-1",
                name: <span>{context.t("Content Categories")}</span>,
                exact: false,
                path: `/projects/${projectId}/categories`,
                icon: (
                  <div
                    id="content_categories"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineCategory size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="content_categories"
                      >
                        {context.t("Content Categories")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "8-2",
                name: <span>{context.t("Wallet Profile Inputs")}</span>,
                exact: false,
                path: `/projects/${projectId}/wallet-profile-inputs`,
                icon: (
                  <div
                    id="wallet_inputs"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineAccountBalanceWallet size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="wallet_inputs"
                      >
                        {context.t("Wallet Profile Inputs")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "8-3",
                name: <span>{context.t("Basic Project Settings")}</span>,
                exact: false,
                path: `/projects/${projectId}/settings/edit`,
                icon: (
                  <div
                    id="basic_project_settings"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineSettings size={20} />
                    {inactive && (
                      <UncontrolledTooltip
                        placement="right"
                        target="basic_project_settings"
                      >
                        {context.t("Basic Project Settings")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                ),
              },
              {
                id: "8-4",
                name: "Collaborators",
                exact: false,
                path: `/projects/${projectId}/collaborators`,
                icon: (
                  <div
                    id="project_collaborators_management"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineSupervisedUserCircle size={20} />
                    <UncontrolledTooltip
                      placement="right"
                      target="project_collaborators_management"
                    >
                      {context.t("Collaborators Management")}
                    </UncontrolledTooltip>
                  </div>
                ),
              },
              {
                id: "8-5",
                name: "Billing Address",
                exact: false,
                path: `/projects/${projectId}/billing`,
                icon: (
                  <div
                    id="project_billing_info_management"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineChromeReaderMode size={20} />
                    <UncontrolledTooltip
                      placement="right"
                      target="project_billing_info_management"
                    >
                      {context.t("Billing Address Management")}
                    </UncontrolledTooltip>
                  </div>
                ),
              },
              {
                id: "8-6",
                name: "Orders/Invoices",
                exact: false,
                path: `/projects/${projectId}/project_orders`,
                icon: (
                  <div
                    id="project_orders_management"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineMonetizationOn size={20} />
                    <UncontrolledTooltip
                      placement="right"
                      target="project_orders_management"
                    >
                      {context.t("Orders And Invoices Management")}
                    </UncontrolledTooltip>
                  </div>
                ),
              },
              {
                id: "8-7",
                name: "Push Links",
                exact: false,
                path: `/projects/${projectId}/push_links`,
                icon: (
                  <div
                    id="push_links_management"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineLink size={20} />
                    <UncontrolledTooltip
                      placement="right"
                      target="push_links_management"
                    >
                      {context.t("Push Link Management")}
                    </UncontrolledTooltip>
                  </div>
                ),
              },
            ],
          },
        ]
      : [];

  useEffect(() => {
    onCollapse(inactive);
  }, [inactive, onCollapse]);

  useEffect(() => {
    if (account) dispatch(showCreator(account));
  }, [dispatch, account]);

  const handleCreateProject = () => {
    dispatch(push("/setup/"));
  };

  const changeProject = (projectId) => {
    dispatch(push(`/projects/${projectId}/`));
  };

  const ProjectItem = ({ projectId }) => {
    const projectData = useSelector((state) =>
      getProjectAttributes(state, projectId)
    );

    return (
      <>
        <DropdownItem
          onClick={() => {
            changeProject(projectId);
          }}
        >
          {projectData?.title}
        </DropdownItem>
        <DropdownItem divider />
      </>
    );
  };

  const location = useLocation();

  useEffect(() => {
    const activeMenuParentId = findActiveMenuParentId(
      menuItems,
      null,
      location.pathname
    );
    if (activeMenuParentId !== -1) {
      setOpenMenus([activeMenuParentId]);
    }
  }, []);
  const findActiveMenuParentId = (items, parentId, pathname) => {
    for (let i = 0; i < items.length; i++) {
      const menuItem = items[i];
      if (
        menuItem.path &&
        matchPath(pathname, { path: menuItem.path, exact: true })
      ) {
        return parentId;
      } else if (menuItem.subMenus && menuItem.subMenus.length > 0) {
        const parentId = findActiveMenuParentId(
          menuItem.subMenus,
          menuItem.id,
          pathname
        );
        if (parentId !== -1) {
          return parentId;
        }
      }
    }
    return -1;
  };

  const toggleMenuItem = (id) => {
    if (openMenus?.includes(id)) {
      const remainingMenus = openMenus.filter((menu) => menu !== id);
      setOpenMenus([...remainingMenus]);
    } else {
      setOpenMenus([...openMenus, id]);
    }
  };

  const handleDisconnect = () => {
    if (connector?.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    document.cookie =
      "creator-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    dispatch(push(`/`));
  };

  const renderMenuItems = (items) => {
    return items.map((menuItem, index) => {
      const isActiveParentMenu = openMenus.includes(menuItem.id);
      return (
        <NavItem
          key={index}
          className={classNames("sidebar__menu-item menu-item", {
            "mt-auto": menuItem.spacing,
          })}
        >
          {!menuItem.special ? (
            <>
              {menuItem.path ? (
                <RouterNavLink
                  to={menuItem.path}
                  exact={menuItem.exact}
                  isActive={menuItem.isActiveRule}
                  activeClassName="active"
                  className="sidebar__menu-link nav-link d-flex align-items-center"
                >
                  <div className="sidebar__menu-item-icon">{menuItem.icon}</div>
                  <span className="sidebar__menu-item-name ms-2">
                    {menuItem.name}
                  </span>
                </RouterNavLink>
              ) : (
                <>
                  {!menuItem.cta ? (
                    <div
                      className="sidebar__menu-heading nav-link cursor-pointer d-flex align-items-center"
                      onClick={() => toggleMenuItem(menuItem.id)}
                    >
                      <div className="sidebar__menu-heading-icon">
                        {menuItem.icon}
                      </div>
                      <span className="sidebar__menu-heading-name ms-2">
                        {menuItem.name}
                      </span>
                      <MdOutlineKeyboardArrowRight
                        size={20}
                        className="ms-auto"
                      />
                    </div>
                  ) : (
                    <div
                      className={classNames("py-4", inactive ? "px-2" : "px-4")}
                    >
                      <Button
                        onClick={() => toggleMenuItem(menuItem.id)}
                        color="secondary"
                        size="lg"
                        className="ms-2 d-flex align-items-center"
                      >
                        <div className="sidebar__menu-heading-icon">
                          {menuItem.icon}
                        </div>
                        <span className="sidebar__menu-heading-name ms-2">
                          {menuItem.name}
                        </span>
                      </Button>
                    </div>
                  )}
                </>
              )}{" "}
            </>
          ) : (
            <>{menuItem.special}</>
          )}
          {menuItem.subMenus && menuItem.subMenus.length > 0 && (
            <div
              className={`sidebar__sub-menu bg-dark ${
                isActiveParentMenu ? "active" : ""
              }`}
            >
              <div
                className="d-flex align-items-center text-light px-3 py-2 cursor-pointer"
                onClick={() => toggleMenuItem(menuItem.id)}
              >
                <MdOutlineKeyboardArrowLeft size={20} />
                <span className="fw-bold ms-2">{menuItem.name}</span>
              </div>
              <Nav className={`nav-pills flex-column`}>
                {renderMenuItems(menuItem.subMenus)}
              </Nav>
            </div>
          )}
        </NavItem>
      );
    });
  };

  return (
    <div
      className={classNames(
        `d-flex flex-column flex-shrink-0 py-2 bg-dark sidebar`,
        { "sidebar--inactive": inactive },
        { active: mobileSidebarActive }
      )}
    >
      <div className="d-flex flex-column sidebar__scrollcontainer ">
        <Nav className="sidebar__menu nav-pills flex-column mb-auto mt-5 main-menu flex-grow-1 position-relative">
          {renderMenuItems(menuItems)}
        </Nav>
        <div className="nav nav-pills flex-column main-menu mb-3">
          {projectData?.host && (
            <div className="menu-item text-decoration-none small">
              <a
                href={`//${projectData?.host}`}
                target="_blank"
                rel="noopener noreferrer"
                className="sidebar__menu-link nav-link d-flex align-items-center"
              >
                <div className="sidebar__menu-item-icon">
                  <div
                    id="view_page"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <MdOutlineDesktopWindows size={20} />
                    {inactive && (
                      <UncontrolledTooltip placement="right" target="view_page">
                        {context.t("View dApp")}
                      </UncontrolledTooltip>
                    )}
                  </div>
                </div>
                <span className="sidebar__menu-item-name ms-2">
                  <span>{context.t("View dApp")}</span>
                </span>
              </a>
            </div>
          )}
          <div
            className="menu-item text-decoration-none small"
            style={{ cursor: "pointer" }}
          >
            <div className="nav-link" onClick={() => handleDisconnect()}>
              <div
                className={classNames("d-flex menuwrapper", {
                  "menuwrapper--inactive": inactive,
                })}
              >
                <div>
                  <MdOutlineLogout size={20} />
                </div>
                <span className="ms-2">{context.t("Log Out")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="nav nav-pills main-menu mb-3">
          <div className="menu-item text-decoration-none w-100 px-4">
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => {
                setDropdownOpen(!dropdownOpen);
              }}
              style={{ position: "unset" }}
            >
              <DropdownToggle
                caret={!inactive}
                color="light"
                className={`w-100 d-flex align-items-center menu-wrapper ${
                  inactive ? "menuwrapper--inactive" : ""
                }`}
              >
                <div
                  id="projects"
                  className="d-flex align-items-center justify-content-center"
                >
                  <MdOutlineLibraryBooks size={20} />
                  {inactive && (
                    <UncontrolledTooltip placement="right" target="projects">
                      {context.t("Projects")}
                    </UncontrolledTooltip>
                  )}
                </div>
                <span className={`${inactive ? "" : "mx-2"}`}>
                  {!projectData && <>{context.t("Projects")}</>}
                  {projectData?.title && (
                    <>
                      {projectData.title.length <= 17
                        ? projectData.title
                        : projectData.title.slice(0, 17) + "..."}
                    </>
                  )}
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-dark text-small shadow ms-3">
                {currentCreatorRelationships?.projects &&
                  currentCreatorRelationships?.projects?.data?.map(
                    (projectRelData) => (
                      <ProjectItem
                        projectId={projectRelData.id}
                        key={projectRelData.id}
                      />
                    )
                  )}
                <DropdownItem onClick={handleCreateProject}>
                  + {context.t("Add Project")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div
          className="small text-light mb-3 d-flex allign-items-center"
          style={{ marginLeft: "2rem", marginRight: "2rem" }}
        >
          {!inactive && (
            <>
              <div id="sidebar__copyright">
                <MdOutlineCopyright />
              </div>
              <span className="ms-2">Bowline {currentYear}</span>
            </>
          )}
          <div
            className="cursor-pointer ms-auto"
            onClick={() => setInactive(!inactive)}
          >
            {!inactive ? (
              <MdOutlineFirstPage size={20} />
            ) : (
              <MdOutlineLastPage size={20} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Sidebar.contextTypes = {
  t: PropTypes.func,
};

Sidebar.propTypes = {
  onCollapse: PropTypes.func,
  projectId: PropTypes.string,
  mobileSidebarActive: PropTypes.bool,
};

Sidebar.defaultProps = {};

/** export the component as redux connected component */
export default withRouter(Sidebar);
